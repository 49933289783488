import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import Spacer from 'client/shared/blocks/spacer'
import { Heading } from 'client/shared/blocks/heading'
import ErrorCartoon from 'client/shared/blocks/error-cartoon'
import { LayoutColumn } from 'client/shared/blocks/layout-column'
import Button from 'client/shared/blocks/button'

import './status-error.styl'

const STATUS_CODES_WITH_DESCRIPTION = [403, 404, 500]

type Props = {
  status: number
  domain: string
  query?: QueryParams
}

class StatusError extends Component<Props> {
  getStatus(): number {
    const { status } = this.props
    return STATUS_CODES_WITH_DESCRIPTION.includes(status) ? status : 500
  }

  render(): JSX.Element {
    const { domain, query } = this.props
    const status = this.getStatus()

    return (
      <div className="status-error">
        <LayoutColumn>
          <Spacer size={56} />
          <ErrorCartoon />
          <Spacer size={24} />
          <Heading>
            <Trans id={`errors.${status}_header`} />
          </Heading>
          <Spacer size={16} />
          <Trans id={`errors.${status}_text`} />
          <Spacer size={24} />
          {this.renderButtonToLibrary(status, domain, query)}
          <Spacer size="bottom-page" />
        </LayoutColumn>
      </div>
    )
  }

  renderButtonToLibrary(
    status: number,
    domain: string,
    query?: QueryParams,
  ): JSX.Element | null {
    if (status === 404 || status === 403) {
      // Using the absolute path here, because using the relative path will change
      // the component's status prop during transition to a different point, which
      // will display the interface characteristic of 500 error during route transition.
      // This can be changed back to relative paths if/when we enable proper code splitting
      const path = `${urlFor.absoluteRoot(domain)}${urlFor.library(query)}`
      return (
        <Button path={path} kind="inline">
          <Trans id={`buttons.explore_library`} />
        </Button>
      )
    } else return null
  }
}

export default StatusError
