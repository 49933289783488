import React from 'react'
import { Route, Redirect } from 'react-router'
import { App } from 'client/errors/app'
import BrowserErrorPage from 'client/errors/pages/browser-error-page'
import StatusErrorPage from 'client/errors/pages/status-error-page'

export default (
  <Route path="/" component={App}>
    <Redirect from="/languages" to="/library" />
    <Route path="/errors/browser" component={BrowserErrorPage} />
    <Route path="/errors/403" component={StatusErrorPage} status={403} />
    <Route path="/errors/404" component={StatusErrorPage} status={404} />
    <Route path="/errors/500" component={StatusErrorPage} status={500} />
    <Route path="*" component={StatusErrorPage} />
  </Route>
)
