import { routerReducer } from 'react-router-redux'

import storeCreator from 'shared/tools/store-creator'

import appReducer from 'client/shared/reducers/app-reducer'
import languagesReducer from 'client/shared/reducers/languages-reducer'
import analyticsReducer from 'client/shared/reducers/analytics-reducer'
import currentUserReducer from 'client/shared/reducers/current-user-reducer'
import alertReducer from 'client/shared/reducers/alert-reducer'
import popupReducer from 'client/shared/reducers/popup-reducer'
import authReducer from 'client/shared/reducers/auth-reducer'
import subscriptionReducer from 'client/bookmate/reducers/subscription-reducer'
import notificationsReducer from 'client/shared/reducers/notifications-reducer'
import fakePageReducer from 'client/shared/reducers/fake-page-reducer'

const reducers = {
  app: appReducer,
  languages: languagesReducer,
  analytics: analyticsReducer,
  currentUser: currentUserReducer,
  alert: alertReducer,
  routing: routerReducer,
  popup: popupReducer,
  auth: authReducer,
  subscription: subscriptionReducer,
  notifications: notificationsReducer,
  fakePage: fakePageReducer,
}

export default storeCreator(reducers)
