import React, { Component } from 'react'
import config from 'config'
import prepareComponent from 'client/shared/decorators/prepare-component'
import AppWrapper from 'client/shared/app-wrapper'

import * as userActions from 'client/shared/reducers/current-user-reducer'
import * as languagesActions from 'client/shared/reducers/languages-reducer'

import './app.styl'

async function prepare({ store }) {
  const { dispatch } = store

  await dispatch(userActions.loadContext())

  const { currentUser } = store.getState()

  const actions = [
    languagesActions.setAvailableUserLanguages(config.locales),
    languagesActions.getUserLanguageTranslations(config.fallbackLocale),
    languagesActions.getUserLanguageTranslations(currentUser.data.locale),
  ].map(dispatch)

  await Promise.all(actions)
}

const prepareWrapper = prepareComponent(prepare, {
  userListener: true,
  name: 'App',
})

class _App extends Component {
  render(): JSX.Element {
    return <AppWrapper {...this.props} />
  }
}
export const App = prepareWrapper(_App)
