import React from 'react'
import SVGInline from 'react-svg-inline'

import ErrorImage from './error-cartoon.svg'

import './error-cartoon.styl'

function ErrorCartoon(): JSX.Element {
  return (
    <div className="error-cartoon">
      <SVGInline svg={ErrorImage} className="error-cartoon__image" />
    </div>
  )
}

export default ErrorCartoon
