import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './browser-error.styl'

import Spacer from 'client/shared/blocks/spacer'
import { Heading } from 'client/shared/blocks/heading'
import { LayoutColumn } from 'client/shared/blocks/layout-column'
import { MinimalHeader } from 'client/shared/blocks/minimal-header'

export default class BrowserError extends Component {
  render() {
    return (
      <div>
        <MinimalHeader onlyLogo />
        <LayoutColumn>
          <Spacer size={80} />
          <Heading kind="kazimir">
            <Trans id="errors.browser_header" />
          </Heading>
          <Spacer size={24} />
          <Trans id="errors.browser_text" />
          <Spacer size={24} />
          <div className="browser-error__item">
            <div className="browser-error__image browser-error__image_chrome"></div>
            <a
              className="browser-error__link"
              href="https://www.google.com/chrome/"
            >
              Сhrome
            </a>
          </div>
          <div className="browser-error__item">
            <div className="browser-error__image browser-error__image_safari"></div>
            <a
              className="browser-error__link"
              href="https://www.apple.com/safari/"
            >
              Safari
            </a>
          </div>
          <div className="browser-error__item">
            <div className="browser-error__image browser-error__image_firefox"></div>
            <a
              className="browser-error__link"
              href="https://www.mozilla.org/en-US/firefox/"
            >
              Firefox
            </a>
          </div>
          <div className="browser-error__item">
            <div className="browser-error__image browser-error__image_edge"></div>
            <a
              className="browser-error__link"
              href="https://www.microsoft.com/en-us/windows/microsoft-edge"
            >
              Microsoft
              <br />
              Edge
            </a>
          </div>
          <Spacer size="bottom-page" />
        </LayoutColumn>
      </div>
    )
  }
}
