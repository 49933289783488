import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import { MinimalHeader } from 'client/shared/blocks/minimal-header'
import HeadTitle from 'client/shared/blocks/head-title'
import StatusError from 'client/errors/blocks/status-error'
import { State } from 'shared/types/redux'
import { Locale } from 'client/shared/types/current-user'
import { QueryParams } from 'shared/tools/url-helper'

type Props = {
  resStatus: number
  route: { status: string }
  domain: Locale
  query: QueryParams
} & withI18nProps

class StatusErrorPage extends Component<Props> {
  getResponseStatus() {
    const { resStatus } = this.props
    const {
      route: { status },
    } = this.props
    return status || resStatus
  }

  render(): JSX.Element {
    const { domain, query } = this.props
    const resStatus = this.getResponseStatus()

    return (
      <Fragment>
        {this.renderTitle(resStatus)}
        <MinimalHeader onlyLogo />
        <StatusError status={Number(resStatus)} domain={domain} query={query} />
      </Fragment>
    )
  }

  renderTitle(status) {
    const { i18n } = this.props

    return status === 404 && <HeadTitle>{i18n.t`errors.404_title`}</HeadTitle>
  }
}

const wrappers = compose(
  connect((state: State) => ({
    resStatus: state.app.resStatus,
    domain: state.app.domain,
    query: state.app.storedQuery,
  })),
  withI18n({ update: true }),
)

export default wrappers(StatusErrorPage)
